export const inputFieldsData = {
  person: [
    {
      label: 'Ime',
      id: 'name',
      type: 'text',
      required: 'Prosimo vnesite ime',
      validation: false,
    },
    {
      label: 'Priimek',
      id: 'surname',
      type: 'text',
      required: 'Prosimo vnesite priimek',
      validation: false
    },
    {
      label: 'E-naslov',
      id: 'email',
      type: 'email',
      required: 'Prosimo vnesite elektronski naslov',
      validation: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    },
    {
      label: 'Telefon',
      id: 'phone',
      type: 'tel',
      required: 'Prosimo vnesite številko telefona',
      validation: false
    },
    {
      label: 'Ulica in hišna številka',
      id: 'address',
      type: 'text',
      required: 'Prosimo vnesite ulico in hišno številko',
      validation: false
    },
    {
      label: 'Mesto',
      id: 'city',
      type: 'text',
      required: 'Prosimo vnesite mesto',
      validation: false
    },
    {
      label: 'Poštna številka',
      id: 'postcode',
      type: 'number',
      required: 'Prosimo vnesite poštno številko',
      validation: /^[0-9]+$/
    },
    {
      label: 'Država',
      id: 'country',
      type: 'country',
      required: 'Prosimo izberite državo',
      validation: false
    },
  ],
  company: [
    {
      label: 'Naziv podjetja',
      id: 'companyName',
      type: 'text',
      required: 'Prosimo vnesite ime podjetja',
      validation: false,
    },
    {
      label: 'Ulica in hišna številka',
      id: 'companyAddress',
      type: 'text',
      required: 'Prosimo vnesite ulico in hišno številko',
      validation: false
    },
    {
      label: 'Mesto',
      id: 'companyCity',
      type: 'text',
      required: 'Prosimo vnesite mesto',
      validation: false
    },
    {
      label: 'Poštna številka',
      id: 'companyPostcode',
      type: 'number',
      required: 'Prosimo vnesite poštno številko',
      validation: /^[0-9]+$/
    },
    {
      label: 'Država',
      id: 'companyCountry',
      type: 'country',
      required: 'Prosimo izberite državo',
      validation: false
    },
    {
      label: 'Kontaktna oseba',
      id: 'contactPerson',
      type: 'text',
      required: true,
      validation: false,
    },
    {
      label: 'Email',
      id: 'companyEmail',
      type: 'email',
      required: 'Prosimo vnesite elektronski naslov',
      validation: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    },
    {
      label: 'Telefon',
      id: 'companyPhone',
      type: 'tel',
      required: 'Prosimo vnesite številko telefona',
      validation: false
    },
    {
      label: 'Št. naročilnice',
      id: 'customerOrderId',
      type: 'text',
      required: false,
      validation: false,
      helperText: 'Številka naročilnice bo navedena na računu'
    },
    {
      label: 'ID za DDV',
      id: 'companyID',
      type: 'text',
      required: false,
      validation: false,
      helperText: 'Identifikacijska številka za DDV'
    },
    
  ],
  shipping: [
    {
      label: 'Naslovnik',
      id: 'addressee',
      type: 'text',
      required: 'Prosimo vnesite naslovnika',
      validation: false,
    },
    {
      label: 'Pripis k naslovniku ',
      id: 'addressee2',
      type: 'text',
      required: false,
      validation: false,
      helperText: 'Ime podjetja, nadstopje, stanovanje itd.'
    },
    {
      label: 'Ulica in hišna številka',
      id: 'shippingAddress',
      type: 'text',
      required: 'Prosimo vnesite ulico in hišno številko',
      validation: false
    },
    {
      label: 'Mesto',
      id: 'shippingCity',
      type: 'text',
      required: 'Prosimo vnesite mesto',
      validation: false
    },
    {
      label: 'Poštna številka',
      id: 'shippingPostcode',
      type: 'number',
      required: 'Prosimo vnesite poštno številko',
      validation: /^[0-9]+$/
    },
    {
      label: 'Država',
      id: 'shippingCountry',
      type: 'country',
      required: 'Prosimo izberite državo',
      validation: false
    },

  ]
}