import React, {useState, useContext, useRef} from 'react';

import {Box} from '@mui/system';
import { Typography } from '@mui/material';

import { cartContext } from '../contexts';
import { Container } from '../grid';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CartStepper from '../components/cart/CartStepper';

import Lottie from "lottie-react";
import completeOrderAnimation from './../lottie/order-completed.json'


// Stripe Promise
const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PK}`)

const stripeOptions = {
  locale: 'sl',
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;500&display=swap'
  }]
}

export default function Checkout() {

  const {cart} = useContext(cartContext)
  const [finished, setFinished] = useState(false)

  const topRef = useRef(null)


  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (  
    <Box ref={topRef}>
      <Container 
        px={{
          xs: 0,
          lg:10
        }}
        pt={{
          xs: 3
        }}
        pb = {{
          lg: 14
        }}
        sx = { (theme) => ({
          maxWidth: {
            xs: '1280px',
            xxxl: '1400px'
          },
          margin: '0 auto',
          width: '100%',
        })}
      >
        {cart?.length > 0 ?
          <Elements stripe={stripePromise} options={stripeOptions}>
            <CartStepper scrollToTop={scrollToTop} setFinished={setFinished} />
          </Elements>
        : 
          <Container sx = { (theme) => ({
              textAlign: 'center'
            })}>
            {finished ? 
            <>
              <Lottie style={{
                width: '10rem',
                margin: '0 auto'
              }} animationData={ completeOrderAnimation} loop={false} />
              <Typography variant="h3">Naročilo je bilo uspešno oddano.</Typography>
              <Typography variant="p">Povzetek naročila ste prejeli na elektronski naslov <strong>{finished.email}.</strong><br/> Če ne najdete sporočila, prosimo preverite med nezaželeno pošto.</Typography>
            </>
            :
            <Typography variant="h3">Košarica je prazna</Typography>
            }
          </Container>
        }
      </Container>
    </Box>  
      
  );
}