import React, {useState, useContext, useEffect, useRef} from 'react';

import {Box, styled} from '@mui/system';
import Grid  from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import LinearProgress from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Collapse, Typography, TextField } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { TransitionGroup } from 'react-transition-group';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'gatsby';

import { cartContext } from '../../contexts';
import { removeAll } from '../../contexts/actions';

import { inputFieldsData } from './inputFieldsData';

import { GridContainer } from '../../grid';
import StepNumber from './StepNumber';
import CustomerTypesSection from './CustomerTypeSection';
import CartSection from './CartSection';
import CartSummary from './CartSummary';
import CustomerData from './CustomerData';
import ShippingData from './ShippingData';
import PaymentSection from './PaymentSection';

import { selectPrice, getShippingCost, getDiscount } from '../../utility/prices';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const steps = ['Košarica', 'Podatki o naročilu', 'Način plačila'];
const errorMsg = 'Prišlo je do napake. Prosimo poskusite ponovno. V primeru ponovitve napake nas prosim obvestite po elektronski pošti: info@aristej.si'

const StepWrapper = styled(Box)(
  ({ theme }) => `
    max-width: 700px;
    margin: 0 auto;

    ${theme.breakpoints.up('lg')} {
      max-width: 'auto'
    }
`)

const CheckboxGroup = styled(FormControlLabel)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(4)};
    align-items: flex-start;

    & .MuiCheckbox-root {
      margin-top: -9px
    }

    & .MuiFormControlLabel-label {
      font-size: 0.8rem;
    }

    & .MuiFormControlLabel-label a {
      font-weight: bold;
      text-decoration: underline;
    }
`)


export default function CartStepper({scrollToTop, setFinished}) {

  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  const {cart, dispatchCart} = useContext(cartContext)
  const [step, setStep] = useState(0)
  const [total, setTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [shippingCost, setShippingCost] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [books, setBooks] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [cardError, setCardError] = useState(true)
  const [clientSecret, setClientSecret] = useState(null)
  const [newsletter, setNewsletter] = useState(true)
  const [policy, setPolicy] = useState(false)

  // CUSTOMER TYPE
  const localCustomerType = localStorage.getItem('customerType')
  const [customerType, setCustomerType] = useState(localCustomerType || 'person')

  // INITIAL DATA

  let initCustomerData = {
    phone: '+386',
    country: {code: 'SI', label: 'Slovenia'},
    companyPhone: '+386',
    companyCountry: {code: 'SI', label: 'Slovenia'},
  }

  // Set errors to false for fields that already have values
  let initErrors = {
    country: false,
    companyCountry: false
  }
  

  const localCustomerData = JSON.parse( localStorage.getItem('customerData') )

  // Check if data are saved in local storage
  if (localCustomerData?.invoice){
    
    // add it to init state of costumer data
    initCustomerData = {
      ...initCustomerData,
      ...localCustomerData.invoice
    }
    // set error to false for field that are in local storage
    Object.keys( localCustomerData.invoice ).forEach(field => {
      if (localCustomerData.invoice[field]) {
        initErrors[field] = false
      }
    }) 
    
  }

  const [customerData, setCustomerData] = useState(initCustomerData)

  // SHIPPING DATA
  const initShippingData =  (localCustomerData?.shipping) ? localCustomerData.shipping : false
  const [shippingData, setShippingData] = useState(initShippingData)

  if (initShippingData) {
    // set error to false for field that are in local storage
    Object.keys( initShippingData).forEach(field => {
      if (initShippingData[field]) {
        initErrors[field] = false
      }
    }) 
  }

  const [errors, setErrors] = useState(initErrors)
  const [loading, setLoading] = useState(false)
  const [paymentError, setPaymentError] = useState(false)


  const cardFormRef = useRef(null)

  // STRIPE
  const stripe = useStripe()
  const elements = useElements()

  // Remove previous payment intent from local storage
  localStorage.removeItem('itentID')


  /** FUNCTIONS */
  const completeOrder = () => {
    
    const _customerData = {}
    inputFieldsData[customerType].forEach( field => _customerData[field.id] = customerData[field.id] )

    const formatedData = getFormattedData()

    

    const data = {
      customerType,
      customerData: _customerData,
      shippingData,
      paymentMethod,
      cart: cart.map(item => ({
        id: item.id,
        title: item.title,
        type: item.type,
        qty: item.qty,
      })),
      total: total.toFixed(2),
      subtotal,
      shippingCost,
      discount,
      newsletter,
      msg: customerData.msg,
      billingFormattedData: formatedData.billingDetails,
      contactFormattedData: formatedData.contactDetails,
      shippingFormattedData: formatedData.shippingDetails
    }

    // console.log(JSON.stringify(data))

    axios({
      url: `${process.env.GATSBY_STRAPI_API_URL}/api/order/complete`,
      method: 'post',
      headers: {
        'Authorization': `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
      },
      data: data
    }).then((result) => {
      console.log(result)
      dispatchCart(removeAll())
      setFinished({
        status: 'success',
        email: (customerType === 'company') ?  customerData.companyEmail : customerData.email
      })
      setTimeout(()=> scrollToTop(), 250)
    }).catch((e) => {
      console.error(e)
      setPaymentError(`Prišlo je do napake. Prosimo osvežite stran in poskusite ponovno. V primeru ponavljajoče napake nas prosimo kontaktirajtje na info@aristej.`) 
    })
  }

  const handleCardPayment = async () => {
    setLoading(true)
    const idempotencyKey = uuidv4()
    const cardElement = elements.getElement(CardElement)
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          address: {

          },
          email: 'primoz.weingerl@gmail.com',
          name: 'Robert Novak'
        }
      }
    }, { idempotencyKey })

    if ( result.error ) {
      setLoading(false)
      setPaymentError(errorMsg)
      console.error(result.error)
      
    } else if (result.paymentIntent.status === 'succeeded') {
      setLoading(false)
      setPaymentError(false)
      localStorage.removeItem('itentID')
      completeOrder()
    }
  }

  const handleStepClick = (ind) => {
    setStep(ind)
    setTimeout(()=> scrollToTop(), 250)

  }

  const handleBackClick = (ind) => {
    if (step === 2) {
      setPaymentMethod(null)
    }
    if (step === 0) return
    
    handleStepClick(step - 1)
    
  }

  const handleNextClick = async () => {
    // if it is on the second step
    if (step === 1) {
      if (validateData()) {
        handleStepClick(step + 1)
      }
    // if it is on the final step
    } else if (step === 2) {
      
      // First validate if all customer information was set
      if (validateData()) {

        // if payment method is card
        if (paymentMethod === 'card') {
          if (clientSecret) {
            handleCardPayment()
          } else {
            console.error('Client secret is not set.')
            setPaymentError(errorMsg)
          }
        // If payment method is prepay or onDelivery
        } else {
          completeOrder()
        }
      } else {
        console.log('Error with validating data.')
      }

    
    // if it is on the first step
    } else {
      handleStepClick(step + 1)
    }
    
  }

  const saveToLocalStorage = (field, id, value) => {

    const localCustomerData = JSON.parse( localStorage.getItem('customerData') )

    // if local data object exist
    if (localCustomerData !== null) {

      // if field object doesn't exist first create it
      if (!localCustomerData[field]) {
        localCustomerData[field] = {}
      }
      localCustomerData[field][id] = value
      localStorage.setItem('customerData', JSON.stringify( localCustomerData ));
    } else {
      // otherwise create new object and add value for the field
      const data = {}
      data[field] = {}
      data[field][id] = value
      localStorage.setItem('customerData', JSON.stringify( data ));
    }
  }
  
  const clearLocalStorage = (field) => {
    const _localStorage = JSON.parse(localStorage.getItem('customerData'))
    if (_localStorage && _localStorage[field]){
      delete _localStorage[field]
      localStorage.setItem('customerData',  JSON.stringify( _localStorage) )
    }
  }

  const validateData = () => {
    const requiredFields = {}
    const _errors = {...errors}
    
    Object.keys(inputFieldsData).forEach( section => {
      if (section === customerType || ( section === 'shipping' && shippingData)) {
        inputFieldsData[section].forEach((field => {
          if (field.required && (
            _errors[field.id] === undefined || _errors[field.id] === null || _errors[field.id])
            ) {
            requiredFields[field.id] = 'required'
          }

        }))
      } else {
        // remove fields from previous set
        inputFieldsData[section].forEach((field => {
          if (_errors[field.id]) {
            delete _errors[field.id]
          }
        }))
      }
    })

    // Set new errors for required fields
    let newErrors = {..._errors, ...requiredFields}

    // console.log(newErrors)
    setErrors(newErrors)

    // Return true if there is no errors, all fields are set to false
    return Object.values(newErrors).every(v => v === false) 
  }

  const submitButton = () => {
    
    const lastStep = step === steps.length - 1
    let disabled = true
    
    if ( !lastStep ) {
      disabled = false
    } else {
      if ( paymentMethod === 'prepay' || paymentMethod === 'onDelivery' || paymentMethod === 'eRacun') {
        disabled = policy ? false : true
      } else {
        if (!cardError && clientSecret) {
          disabled = policy ? false : true
        }
      }
    }

    return (
      loading ? 
       ( <Box sx = { (theme) => ({
        display: 'flex',
        justifyContent: 'center',
        mt: 6

       })}>
          <CircularProgress />
       </Box> ) : (
        <Button
          disabled = {disabled}
          onClick={handleNextClick}
          variant="contained"
          sx = { (theme) => ({
            mt: 8,
            width: {
              xs: '100%'
            }
          })}
        >
          {lastStep ? 'Zaključi nakup' : 'Nadaljujte z naslednjim korakom'}
        </Button>
      )
      
    )
  }

  const handlePaymentSelection = (method) => {
    // Remove errors if any
    setPaymentError(false)

    // If card is selected 
    if (method === 'card') {
      setPaymentIntent()
    }
    setPaymentMethod(method)
  }

  const setPaymentIntent = () => {

    const storedIntent = localStorage.getItem('itentID')
    const idempotencyKey =  uuidv4();

    setClientSecret(null)

    axios({
      url: `${process.env.GATSBY_STRAPI_API_URL}/api/order/process`,
      method: 'post',
      headers: {
        'Authorization': `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
      },
      data: {
        storedIntent: storedIntent,
        idempotencyKey: idempotencyKey,
        customerType: customerType,
        customerData: customerData,
        shippingData: shippingData,
        cart: cart.map(item => ({
          id: item.id,
          type: item.type,
          qty: item.qty,
        })),
        total: total.toFixed(2),
        subtotal,
        shippingCost,
        discount
      }
    }).then((result) => {
      setClientSecret(result.data.clientSecret)
      localStorage.setItem('inentID', result.data.intentID)
    }).catch((e) => {
      console.error(e)
      setPaymentError('Prišlo je do napake. Trenutno ni mogoče plačati s kreditno kartico.')
    })
  }

  const setErrorsHandler = (prop, errorMsg) => {
    const newErrors = {...errors}
    newErrors[prop] = errorMsg
    setErrors(newErrors)
  }

  const customerTypeHandler = (type) => {
    setCustomerType(type)
    localStorage.setItem('customerType', type)
  }


  const getFormattedData = () => {
    const customer = ( customerType === 'company' ) ? customerData.companyName : customerData.name + ' ' + customerData.surname
    const address = ( customerType === 'company' ) ? customerData.companyAddress : customerData.address
    const postcode = ( customerType === 'company' ) ? customerData.companyPostcode : customerData.postcode
    const city = ( customerType === 'company' ) ? customerData.companyCity : customerData.city
    const country = ( customerType === 'company' ) ? customerData.companyCountry?.label : customerData.country?.label
    const email = ( customerType === 'company' ) ? customerData.companyEmail : customerData.email
    const phone = ( customerType === 'company' ) ? customerData.companyPhone : customerData.phone
    const contactPerson = ( customerType === 'company' ) ? customerData.contactPerson : null

    const companyID = ( customerType === 'company' ) ? customerData.companyID : null
    const orderID = ( customerType === 'company' ) ? customerData.customerOrderId : null

    const shippingLine1 = shippingData ? shippingData.addressee : customer
    const shippingLine2 = shippingData ? shippingData.addressee2 : contactPerson
    const shippingAddress = shippingData ? shippingData.shippingAddress : address
    const shippingCity = shippingData ? shippingData.shippingCity : city
    const shippingPostcode = shippingData ? shippingData.shippingPostcode : postcode
    const shippingCountry = shippingData ? shippingData.shippingCountry?.label : country

    const billingDetails = `${customer}\n${address}\n${postcode} ${city}\n${country}\n${ companyID ? `ID za DDV: ${companyID}\n` : `` }${ orderID ? `Št. naročilnice: ${orderID}` : `` }`
    
    const contactDetails = `${contactPerson ? `${contactPerson}\n` : ``}E-naslov: ${email}\nTelefon: ${phone}`

    const shippingDetails = `${shippingLine1}\n${shippingLine2 ? `${shippingLine2}\n` : '' }${shippingAddress}\n${shippingPostcode} ${shippingCity}\n${shippingCountry}`

    return {
      billingDetails,
      contactDetails,
      shippingDetails
    }
  }
  

  /**
   *  USE EFFECT
   */

  useEffect(() => {
    
    // Get data calculating price
    const _books = cart.map(book => ({
      price: selectPrice(book.prices, customerType),
      total: selectPrice(book.prices, customerType) * book.qty,
      ...book
    }))
    
    // Calculate discount
    const _discount = getDiscount(_books, customerType)
    const _subtotal = _books.reduce((prev, current) => prev + current.total, 0 )
    const _shippingCost = getShippingCost(_books, _subtotal - _discount, customerType, customerData, shippingData)
    
    setBooks(_books)
    setSubtotal(_subtotal)
    setShippingCost(_shippingCost)
    setDiscount(_discount)
    setTotal(
      _subtotal + _shippingCost - _discount
    )

    // Update payment intent if card method is selected 
    if (step === 2) {
      if (paymentMethod === 'card') {
        setPaymentIntent()
      }
    }

  }, [customerType, cart, step, paymentMethod, shippingData, customerData])





  const styles = {
    px: {
      xs: 5,
      sm2: 8,
      lg: 0
    },
    pt: {
      xs: 8,
      lg: 8
    },
    mt: 2
  }

  return (
  <Box>
    <GridContainer sx = { (theme) => ({
      alignItems: 'stretch',
    })}>
      <Grid item xs={12} lg={7} xxl={7}>

      <Box sx = { (theme) => ({
        width: '100%',
        bgcolor: theme.palette.grey1,
        position: {
          xs: 'sticky',
          lg: 'relative'
        },
        top: 0,
        zIndex: 15
      })}>

        <Stepper
          nonLinear
          activeStep={step} 
          connector={null}
          sx = { (theme) => ({
            alignItems: 'flex-start',
            width: '100%'
          })}
        >
          {steps.map((label, ind) => (
            <Step 
              key={label}
              sx = { (theme) => ({
                flex: '1 1 0px'
              })}
            >
              <StepButton sx = { (theme) => ({
                m: 0,
                p:0,
                py: 4
              })}
              centerRipple={true}
              onClick={()=> handleBackClick()}
              disabled={step < ind}
              >
                <StepLabel 
                  StepIconComponent={StepNumber}
                  sx = { (theme) => ({
                    textTransform: 'uppercase',
                    flexDirection: {
                      xs: 'column',
                      lg: 'row'
                    },
                    justifyContent: {
                      lg: 'flex-end'
                    },
                    gap: {
                      xs: 0,
                      lg: 3
                    },
                    '& .MuiStepLabel-iconContainer': {
                      padding:0,
                    },

                    '& .MuiStepLabel-label': {
                      mt: {
                        xs: theme.spacing(2),
                        sm: theme.spacing(3),
                        lg: 0
                      },
                      fontWeight: 400,
                      fontSize: '0.75rem',
                    },
                    '& .MuiStepLabel-labelContainer' : {
                      textAlign: {
                        lg: 'left'
                      }
                    }

                  })}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <LinearProgress 
          variant="determinate" 
          value={100/3 * (step+1)} 
          sx = { (theme) => ({
            bgcolor: theme.palette.grey3
          })}     
        />
      </Box>
      
      <Box sx = { (theme) => ({
        overflow: 'hidden'
      })}>
        <TransitionGroup>
        
        {/* STEP 1 */}
        {step === 0 && 
          <Slide direction="right" key={'step1'}>
            <StepWrapper>
              {!lg && 
                <CustomerTypesSection 
                  customerType={customerType} 
                  customerTypeHandler={customerTypeHandler}
                  sx = { (theme) => ({...styles})}
                />
              }
              
              <CartSection
                books={books}
                sx = { (theme) => ({...styles})}
              />
            </StepWrapper>
          </Slide>
        }

        {/* STEP 2 */}
        {step === 1 && 
          <Slide direction="left" key={'step2'}>
            <StepWrapper>
              <CustomerData
                inputFields = {inputFieldsData[customerType]}
                data={customerData}
                setData={(id, value, error) => {
                  setCustomerData({
                    ...customerData,
                    [id]: value
                  })
                  // If value does not have error save it in local storage
                  if (!error || error === 'required') {
                    saveToLocalStorage('invoice', id, value)
                  }
                }}
                customerType={customerType}
                errors={errors}
                setErrors={setErrorsHandler}
                sx = { (theme) => ({...styles})}
              />
              <ShippingData
                inputFields = {inputFieldsData['shipping']}
                data={shippingData}
                onChangeShipping={(e) => {
                  if (e.target.value === 'different') {
                    setShippingData({})
                  } else {
                    setShippingData(false)
                    clearLocalStorage('shipping')
                  }
                }}
                setData={(id, value, error) => {
                  if (!id) {
                    setShippingData(false)
                  } else {
                    setShippingData({
                      ...shippingData,
                      [id]: value
                    })
                    // If value does not have error save it in local storage
                    if (!error || error === 'required') {
                      saveToLocalStorage('shipping', id, value)
                    }
                  }
                }}
                customerType={customerType}
                errors={errors}
                setErrors={setErrorsHandler}
                sx = { (theme) => ({...styles})}
              />

              <Box sx = { (theme) => ({...styles})}>
                <Typography component='h2' variant="cartSectionTitle">Ali nam želite še kaj sporočiti?</Typography>
                <TextField
                  onChange={(event) => {
                    setCustomerData({
                      ...customerData,
                      msg: event.target.value
                    })
                  }}
                  id={'msg'}
                  label={''} 
                  variant="outlined"
                  fullWidth
                  required={false}
                  // type={data.type}
                  value={customerData.msg ? customerData.msg : ''}
                  multiline={true}
                />
              </Box>

            </StepWrapper>
          </Slide>
        }

        {/* STEP 3 */}
        {step === 2 && 
          <Slide direction="left" key={'step2'}>
            <StepWrapper>
              <PaymentSection 
                payment={paymentMethod}
                setPayment={handlePaymentSelection}
                cardRef={cardFormRef}
                cardError={cardError}
                setCardError={setCardError}
                sx = { (theme) => ({...styles})}
                customerType={customerType}
              />
            </StepWrapper>
          </Slide>
        }

        </TransitionGroup>
        </Box>
      </Grid>
      <Grid item xs={12} lg={5} xxl={5} ml={{xl: 'auto'}}>
        <Box sx = { (theme) => ({
          ...styles, 
          mx: 'auto',
          mt: {
            xs: 2, 
            lg: 2
          }, 
          py: {
            lg:0
          },
          position: {
            lg: 'sticky'
          },
          top: theme.spacing(8),
          maxWidth: {
            xs: '700px',
            lg: 'none'
          }
          })}>
          {lg &&
            <Collapse in={step !== 2}>
              <CustomerTypesSection 
                customerType={customerType} 
                customerTypeHandler={customerTypeHandler}
                sx = { (theme) => ({
                  pb: 8, 
                  pt: 4,
                })}
              />
            </Collapse>
          }
          <CartSummary
            subtotal={subtotal}
            discount={discount}
            shipping={shippingCost}
            total={total}
            step={step}
            customerData={customerData}
            customerType={customerType}
            shippingData={shippingData}
            setStep={setStep}
          />


          {step === 2 && 
            <FormGroup sx = { (theme) => ({
              mt: 8
            })}>
              <CheckboxGroup 
                control={
                  <Checkbox 
                    checked={newsletter} 
                    onChange={(e) => setNewsletter(e.target.checked)} 
                  />
                } 
                label="Da, želim prejemati obvestila o novostih vaše založbe (največ trikrat v letu)." 
              />
              <CheckboxGroup 
                control={
                  <Checkbox 
                    checked={policy} 
                    onChange={(e) => setPolicy(e.target.checked)}
                  />
                } 
                label={<span>Z oddajo spletnega naročila se strinjam s <Link to={'/splosni-pogoji'}>splošnimi pogoji poslovanja.</Link></span>} 
              />
            </FormGroup>
          }

          {/* Display errors */}
          <Fade in={!!paymentError} mountOnEnter={true} unmountOnExit={true}>
            <Box sx = { (theme) => ({
              mt: 6
            })}>
                <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
                  {paymentError}
                </Alert>
            </Box>
          </Fade>
          {submitButton()}
          {step !== 0 && <Button sx = { (theme) => ({
            display: 'block',
            m: '0.5rem auto',
          })} variant="underlined" disableRipple={true} onClick={()=>handleBackClick()}>Nazaj na prejšnji korak</Button>}
        </Box>
      </Grid>
    </GridContainer>
  </Box>
  )
}