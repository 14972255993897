import React from 'react';
import TextField from '@mui/material/TextField';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

import CountrySelect from './CountrySelect';
import { isEmpty } from '../../utility';

export default function InputField({data, value, setData, error, setError}) {
  
  const checkValue = (value) => {
    let errorValue = false
    if (data.type === 'tel') {
      if (!matchIsValidTel(value)){
        errorValue = 'notValidated'
      }
    } else {
      if(data.required && isEmpty(value)) {
        errorValue = 'required'
      } else if (data.validation && !value.match(data.validation)){
        errorValue = 'notValidated'
      }
    }
    setError(data.id, errorValue)
    return errorValue
  }

  const onChangeHandler = (value) => {
    const errorValue = checkValue(value)
    setData(data.id, value, errorValue)
  }

  const getHelperText = error => (error === 'required') ? data.required : 'Nepravilna oblika'
  
  switch (data.type) {
    case 'tel':
      return (
        <MuiTelInput
          forceCallingCode  
          error={!!error}
          fullWidth 
          value={value} 
          onChange={(value, info) => onChangeHandler(value)}
          id={data.id}
          label={data.label} 
          variant="outlined"
          required={!!data.required}
          helperText={error && getHelperText(error) }
        />
      )
    case 'country':
      return (
        <CountrySelect
          id={data.id}
          label={data.label}
          value={value}
          onChange={(value) => onChangeHandler(value)}
          error={!!error}
          required={!!data.required}
          helperText={error && getHelperText(error) }
        />
      )
    default:
      return ( 
        <TextField
          onChange={(event) => onChangeHandler(event.target.value)}
          error={!!error}
          id={data.id}
          label={data.label} 
          variant="outlined"
          fullWidth
          required={!!data.required}
          helperText={(error && getHelperText(error)) || data.helperText }
          type={data.type}
          value={value ? value : ''}
        />
      )
  }

}