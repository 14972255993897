import React from 'react';
import { Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import CustomerTypeButton from './BtnCustomerType';
import { PersonIcon, CompanyIcon } from '../icons';
import { Link } from 'gatsby';


export default function CustomerTypesSection({customerType, customerTypeHandler, sx}) {
  return(
    <Box sx = {sx}>
      <Typography component='h3' variant="cartSectionTitle">Vrsta kupca</Typography>
      <Box
        sx = { (theme) => ({
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
            lg: 'column'
          },
          gap: 3
        })}
      >
        <CustomerTypeButton title="Posameznik" Icon={PersonIcon} selected={customerType === 'person'} onClick={() => customerTypeHandler('person')}>
          <Typography variant="p">Posamezniki so upravičeni do spletnih cen.</Typography>
        </CustomerTypeButton>
        <CustomerTypeButton title="Ustanova" Icon={CompanyIcon} selected={customerType === 'company'} onClick={() => customerTypeHandler('company')}>
          <Typography variant="p">Ustanove so upravičene do količinskega popusta. </Typography>
        </CustomerTypeButton>
      </Box>
      <Button sx = { (theme) => ({
        color: theme.palette.black,
        mt: 4
      })} variant="underlined" disableRipple={true} component={Link} to='/splosni-pogoji' >Več o cenovni politiki in popustih</Button>
    </Box>
  )
};
