import React from 'react';
import { styled } from '@mui/system'
import PropTypes from 'prop-types';

const StepNumberRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey2,
  color: theme.palette.primary.main,
  fontWeight: 600,
  width: '2em',
  height: '2em',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white
  })
})
)

function StepNumber(props) {
  const { active, completed, className, icon } = props;
  return (
    <StepNumberRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepNumberRoot>
  );
}

StepNumber.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default StepNumber