import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@emotion/react';
import useMediaQuery from "@mui/material/useMediaQuery"

export default function BtnPaymentMethod({Icon, title, children, selected = true, onClick}) {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      sx = { (theme) => ({
        bgcolor: theme.palette.white,
        py: {
          xs: 3,
          md: 6,
          lg: 8
        },
        px: {
          xs: 6,
          md: 6
        },
        borderWidth: '3px',
        borderColor: selected ? theme.palette.primary.main : theme.palette.white,
        borderStyle: 'solid',
        transition: `border-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
        cursor: 'pointer',
        lineHeight: '115%',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: 5,
        }
      })}
      onClick={onClick}
    >
    <div>
      <Icon sx = { (theme) => ({
        fontSize: {
          xs: '3.5rem',
          lg: '4rem'
        }
      })} />
      <Box sx = { (theme) => ({
        width: '100%'
      })}>
        <Typography variant="p" sx = { (theme) => ({
          fontWeight: 600
        })}>{title}</Typography>
        {desktop && 
          <Collapse in={selected}>
            {children}
          </Collapse>
        }
      </Box>
    </div>
      {!desktop && 
        <Collapse in={selected}>
          <Box sx = { (theme) => ({
            p: 2
          })}>{children}</Box>
        </Collapse>
      }
    </Box>
  )
};
