import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import InputField from './InputField';
import { Radio, RadioGroup, FormControlLabel  } from '@mui/material';

export default function ShippingData({inputFields, customerType = 'person', data, setData, onChangeShipping, sx, setErrors, errors}) {
  return (
    <Box sx={sx}>
      <Typography component='h2' variant="cartSectionTitle">Podatki za dostavo</Typography>
      <Box sx = { (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      })}>
        <RadioGroup
          aria-labelledby="shipping-options"
          name="shipping-options"
          value={data ? 'different' : 'same'}
          onChange={onChangeShipping}
        >
          <FormControlLabel value="same" control={<Radio />} label="Uporabi iste podatke kot za račun" />
          <FormControlLabel value="different" control={<Radio />} label="Drugi podatki za dostavo" />
        </RadioGroup>

        {data && inputFields.map(field => (
            <InputField 
              key={field.id} 
              data={field} 
              value={data[field.id]} 
              setData={setData}
              error={errors[field.id]}
              setError={setErrors}
            />
          ))
        }
      </Box>



    </Box>
  )
};
