import React from 'react';

import { Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { changeDecimal } from '../../utility/prices';


const Label = styled('p')(
  ({ theme, params }) => `
    font-size: 1rem;
    margin:0;
    font-weight: inherit;
`)

const Price = styled('p')(
  ({ theme }) => `
    font-size: 1rem;
    text-align: right;
    margin:0;
    font-weight: inherit;
`)

const Row = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: space-between
`)

const SummarySection = styled(Box)(
  ({ theme }) => `
    & p {
      font-size: 0.9rem;
      margin: 0.25rem 0;
    }
    & h4 {
      margin: 0
    }
`)



export default function CartSummary({total, subtotal, discount, shipping, sx, step, customerData, customerType, shippingData, setStep}) {

  // console.log(shippingData)

  const customer = ( customerType === 'company' ) ? customerData.companyName : customerData.name + ' ' + customerData.surname
  const address = ( customerType === 'company' ) ? customerData.companyAddress : customerData.address
  const postcode = ( customerType === 'company' ) ? customerData.companyPostcode : customerData.postcode
  const city = ( customerType === 'company' ) ? customerData.companyCity : customerData.city
  const country = ( customerType === 'company' ) ? customerData.companyCountry?.label : customerData.country?.label
  const email = ( customerType === 'company' ) ? customerData.companyEmail : customerData.email
  const phone = ( customerType === 'company' ) ? customerData.companyPhone : customerData.phone
  const contactPerson = ( customerType === 'company' ) ? customerData.contactPerson : null

  const companyID = ( customerType === 'company' ) ? customerData.companyID : null
  const orderID = ( customerType === 'company' ) ? customerData.customerOrderId : null


  const shippingLine1 = shippingData ? shippingData.addressee : customer
  const shippingLine2 = shippingData ? shippingData.addressee2 : contactPerson
  const shippingAddress = shippingData ? shippingData.shippingAddress : address
  const shippingCity = shippingData ? shippingData.shippingCity : city
  const shippingPostcode = shippingData ? shippingData.shippingPostcode : postcode
  const shippingCountry = shippingData ? shippingData.shippingCountry?.label : country

  return (
    <Box sx={sx}>
      <Typography component='h2' variant="cartSectionTitle">Povzetek naročila</Typography>

      {( step === 2 ) && (
        <Box sx = { (theme) => ({
          mb: 6,
          borderBottom: 'solid 3px var(--black)',
          pb: 5,
          // display: 'flex',
        })} >
          <Box sx = { (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            flexGrow: 1,
          })}>
            <SummarySection>
              <h4>Podatki za račun</h4>
              <p>
                {customer}<br />
                {address} <br/> {postcode} {city}<br/>
                {country}
              </p>
            
              { companyID &&  <p>ID za DDV: {companyID}</p> }
              { orderID && <p>Št. naročilnice: {orderID}</p> }
            </SummarySection>
            <SummarySection>
              <h4>Kontaktni podatki</h4>
              {contactPerson && <p>{contactPerson}</p>}
              <p>
                <span>E-naslov: </span>{email}<br/>
                <span>Telefon: </span>{phone}
              </p>
            </SummarySection>
            <SummarySection>
              <h4>Podatki za dostavo</h4>
              <p>
                {shippingLine1}<br/>
                {shippingLine2 }
                {shippingLine2 && <br />}
                {shippingAddress} <br/> {shippingPostcode} {shippingCity}<br/>
                {shippingCountry}
              </p>
            </SummarySection>
          </Box>
          <Box sx = { (theme) => ({
            textAlign: 'right',
          })} >
            <Button variant="underlined" disableRipple={true} onClick={()=>setStep(step - 1)}>
              Spremeni podatke
            </Button>
          </Box>
        </Box>
        
      )}
      

      <Box sx = { (theme) => ({
        display:'flex',
        flexDirection: 'column',
        gap: 2
      })}>
        <Row>
          <Label>Skupaj</Label>
          <Price>{`${changeDecimal(subtotal)} EUR`}</Price>
        </Row>
        <Row>
          <Label>Popust</Label>
          <Price>{`- ${changeDecimal(discount)} EUR`} {}</Price>
        </Row>
        <Row>
          <Label>Poštnina</Label>
          <Price>{shipping === 0 ? 'brezplačna' : `${changeDecimal(shipping)} EUR`}</Price>
        </Row>
        <Row style={{fontWeight: 600}}>
          <Label>Skupni znesek</Label>
          <Price>{`${changeDecimal(total)} EUR`}</Price>
        </Row>

      </Box>
    </Box>
  )
};
