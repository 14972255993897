import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import InputField from './InputField';


export default function CustomerData({inputFields, customerType = 'person', data, setData, sx, setErrors, errors}) {
  return (
    <Box sx={sx}>
      <Typography component='h2' variant="cartSectionTitle">Podatki o naročniku</Typography>
      <Box sx = { (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      })}>
        {inputFields.map(field => (
            <InputField 
              key={field.id} 
              data={field} 
              value={data[field.id]} 
              setData={setData}
              error={errors[field.id]}
              setError={setErrors}
            />
          ))
        }
      </Box>



    </Box>
  )
};
