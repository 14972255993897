import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export default function BtnCustomerType({Icon, title, children, selected = true, onClick}) {
  
  return (
    <Box
      sx = { (theme) => ({
        bgcolor: theme.palette.white,
        p: 3,
        display: 'flex',
        gap: 3,
        borderWidth: '3px',
        borderColor: selected ? theme.palette.primary.main : theme.palette.white,
        borderStyle: 'solid',
        alignItems: 'center',
        transition: `border-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
        cursor: 'pointer'
      })}
      onClick={onClick}
    >
      {<Icon sx = { (theme) => ({
        fontSize: '4rem'
      })} />}
      <Box>
        <Typography variant="p" sx = { (theme) => ({
          fontWeight: 600
        })}>{title}</Typography>
        <Typography sx = { (theme) => ({
          fontSize: '0.875rem',
          '& a': {
            fontWeight: 600
          }

        })} varian="p">{children}</Typography>
      </Box>
    </Box>
  )
};
