import React, { forwardRef } from 'react';
import { Link } from 'gatsby';

import { Cover } from './../books/Cover';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import CartCounter from './CartCounter';
import CartDeleteBtn from './CartDeleteBtn';
import { changeDecimal } from '../../utility/prices';

export const CartSingleBook = forwardRef((props, ref) => {
  const data = props.data
  // console.log(data)

  return (
    <div ref={ref} {...props} >
      <Box sx = { (theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: {
          xs: 4
        }
      })}>
        <Cover
          img={data.cover}
          alt={data.title}
          gatsbyimageprops={{
            loading: 'lazy'
          }}
          sx = { (theme) => ({
            width: {
              xs: '25%'
            },
            maxWidth: '100px',
            flexShrink: 0
          })}
        />
        <Box sx = { (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width:'100%'
        })}>
          <Box>
            <Typography sx = { (theme) => ({
              fontSize: {
                xs: '0.875rem',
                lg: '1rem'
              },
              fontFamily: 'var(--sans-font)'
            })} variant='bookArchiveAuthor'>{data.author}</Typography>
            <Typography component={Link} to={data.slug} sx = { (theme) => ({
              fontSize: {
                xs: '1rem',
                lg: '1.15rem'
              },
              fontFamily: 'var(--sans-font)',
              fontWeight: 400
            })} variant='bookArchiveTitle'>{data.title}</Typography>
          </Box>
          <Box sx = { (theme) => ({
            display: 'flex',
            gap: {
              xs: 4,
              lg: 6
            },
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'space-between'
          })}>
            <CartCounter qty={data.qty} id={data.id} />
            <CartDeleteBtn id={data.id} sx = { (theme) => ({
              mr: {
                xs: 'auto'
              }
            })} />
            <Typography 
              variant="subtitle1"
              sx = { (theme) => ({
                fontSize: '1rem',
                fontWeight: 400
              })}
            >
            {`${changeDecimal(data.total)} EUR`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  )
});