import React, {useContext} from 'react';

import { Box, styled } from '@mui/system';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { cartContext } from '../../contexts';
import { addToCart, removeFromCart } from '../../contexts/actions';


export default function CartCounter({id, qty = 1}){

  const {dispatchCart} = useContext(cartContext)

  const addHandler = () => dispatchCart(addToCart({id: id}, 1))

  const removeHandler = () => {
    if (qty > 1) {
      dispatchCart(removeFromCart({id: id}, 1))
    }
  }

  const iconSx = (theme) => ({
    bgcolor: theme.palette.grey2
  })

  const CountNumber = styled('span')(
    ({ theme }) => `
      font-weight: 400
  `)

  return(
    <Box sx = { (theme) => ({
      display: 'flex',
      gap: {
        xs: 1,
        sm: 3,
      },
      flexShrink: 0,

      '& button': {
        flexShrink: 0
      }
    })}>
      <IconButton sx={iconSx} aria-label="add" size="small" onClick={removeHandler}>
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <CountNumber>{qty}</CountNumber>
      <IconButton sx={iconSx} aria-label="add" size="small" onClick={addHandler} >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </Box>
  )
}
