import React, {useContext} from 'react';
import { CartSingleBook } from './CartSingleBook';
import { Box } from '@mui/system';
import { Typography, Button, Grow } from '@mui/material';
import { TransitionGroup} from 'react-transition-group'
import { cartContext } from '../../contexts';
import { removeAll } from '../../contexts/actions';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { DeleteIcon } from '../icons';

export default function CartSection(params) {

  const {dispatchCart} = useContext(cartContext)
  const removeHandler = () => dispatchCart(removeAll())
  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box {...params}>
      {!lg && <Typography component='h3' variant="cartSectionTitle">Izbrane knjige</Typography>}
      <Box sx = { (theme) => ({
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          gap: 6
        }
      })}>
        <TransitionGroup>
          {params.books?.map((book, ind) => (
            <Grow
              key={`${book.id}`}
              timeout={{
                enter: 500,
                appear: 500,
                exit: 500,
              }}
            >
              <CartSingleBook data={book}/>
            </Grow>
          ))}
        </TransitionGroup>
      </Box>
      {params.books?.length >= 2 && 
      <Button sx = { (theme) => ({
        mt: 8,
      })}  disableRipple={true} onClick={removeHandler} startIcon={<DeleteIcon />} size={'large'} variant={'iconLink'}>
        odstrani vse
      </Button>}
    </Box>
  )
};
