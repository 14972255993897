import React from 'react';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { PaymentDeliveryIcon, PaymentInvoiceIcon } from '../icons';
import BtnPaymentMethod from './BtnPaymentMethod';

// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


export default function PaymentSection({payment, setPayment, sx, cardRef, cardError, setCardError, customerType}) {


  // const stripe = useStripe()
  // const elements = useElements()

  // const handleCardSubmit = async event => {
  //   event.preventDefault()
  //   // return if stripe is not initialize
  //   if (!stripe || !elements) return
  // }

  // const handleCardChange = async event => {
  //   console.log(event)
  //   if (event.complete) {
  //     setCardError(false)
  //   } else {
  //     setCardError(true)
  //   }
  // }

  // const cardStyle = {
  //   base: {
  //     fontSize: '16px',
  //     fontFamily: `'Merriweather Sans', sans-serif`,
  //     letterSpacing: '1px',
  //     fontWeight: 300
  //   }
  // }

  // const cardWrapper = (
  //   <Box 
  //     component="form" 
  //     ref={cardRef} 
  //     onSubmit={handleCardSubmit}
  //     sx = { (theme) => ({
  //       mt: 2,
  //       px: 2,
  //       py: 4,
  //       bgcolor: theme.palette.grey1,
  //       mb: 2
  //     })}
  //   >
  //     <CardElement 
  //       options={{
  //         style: cardStyle,
  //         hidePostalCode: true
  //       }} 
  //       onChange={handleCardChange} />
  //   </Box>
  // )

  return(
    <Box sx = {sx}>
      <Typography component='h3' variant="cartSectionTitle">Način plačila</Typography>
      <Box
        sx = { (theme) => ({
          display: 'flex',
          flexDirection: {
            xs: 'column',
            // md: 'row',
          },
          gap: 3,
          justifyContent: 'space-between',
          '& p': {
            fontSize: '0.875rem',
            mt: 2
          }
        })}
      >
        {/* <BtnPaymentMethod 
          Icon={PaymentCardIcon} 
          selected={payment === 'card'} 
          onClick={() => setPayment('card')}
          title="Plačilo s kartico"
        >
          {cardWrapper}
        </BtnPaymentMethod> */}
        <BtnPaymentMethod
          Icon={PaymentInvoiceIcon} 
          selected={payment === 'prepay'} 
          onClick={() => setPayment('prepay')} 
          title="Plačilo po predračunu"
        >
          <Typography variant="body1">
          Po elektronski pošti boste prejeli predračun. Naročeno vam bomo poslali v 1−5 dneh od prejema vašega plačila in vas o tem obvestili.
          </Typography>
        </BtnPaymentMethod>
        <BtnPaymentMethod 
          Icon={PaymentDeliveryIcon} 
          selected={payment === 'onDelivery'} 
          onClick={() => setPayment('onDelivery')}
          title="Plačilo ob prevzemu"
        >
          <Typography variant="body1">
          Račun boste poravnali pri poštnem uslužbencu, ki vam bo dostavil/izročil naročeno. Pri plačilu ob prevzemu vam bo Pošta Slovenije zaračunala vplačnino po svojem ceniku.
          </Typography>
        </BtnPaymentMethod>

        {customerType === 'company' && 
          <BtnPaymentMethod
            Icon={PaymentInvoiceIcon} 
            selected={payment === 'eRacun'} 
            onClick={() => setPayment('eRacun')} 
            title="E-račun"
          >
            <Typography variant="body1">
            Za javne zavode
            </Typography>
          </BtnPaymentMethod>
        }
      </Box>
    </Box>
  )
};
