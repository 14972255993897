import React, {useContext} from 'react';
import { IconButton } from '@mui/material';
import { cartContext } from '../../contexts';
import { removeCompletely } from '../../contexts/actions';
import { DeleteIcon } from '../icons';

export default function CartDeleteBtn({id, sx}){

  const {dispatchCart} = useContext(cartContext)

  const removeHandler = () => dispatchCart(removeCompletely({id}))

  return(
    <IconButton sx={sx} aria-label="remove" size="small" onClick={removeHandler}>
      <DeleteIcon />
    </IconButton>
  )
}
